.card-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    flex-direction: column;
}

.card {
    position: absolute;
    width: 95%;
    height: 100%;
    background-color: #d7d2c9;
    border-radius: 25px;
    box-sizing: border-box;
    text-align: center;
    font-family: Inter, sans-serif;
    z-index: 1600;
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    height: 90%;
    border-radius: 25px 25px 0 0;
    padding: 15px;
    margin: auto;
    text-align: left;
    word-break: break-word;
}

.comment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    height: 90%;
    width: 90%;
    border-bottom: black solid 1px;
    padding: 15px;
    margin: auto;
    text-align: left;
}

.card-content h2 {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 0.9;
    margin: 1rem 0 1rem;
    padding: 0;
}

.card-content p {
    font-size: 1rem;
    margin: 0;
    padding: 0;
}

.card-bottom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    height: 10%;
    width: 100%;
    background-color: white;
    border-radius: 0 0 25px 25px;
    bottom: 0;
}

.card-bottom h2 {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 0.9;
    margin: 1rem 0 1rem;
    padding: 0;
}


.card-bottom-left {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 100%;
    width: 10%;
    padding: 0 1rem 0 1rem;
    margin: auto;
}

.card-bottom-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70%;
    padding: 0 1rem 0 1rem;
    margin: auto;
}

.card-bottom-right {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    height: 100%;
    width: 10%;
    padding: 0 1rem 0 1rem;
    margin: auto;
}

.mini-card {
    height: 150px;
    padding: 5px;
}

.mini-card-content {
    box-shadow: 2px 2px 4px rgb(0 0 0 / 20%);
}


.react-player > video {
    object-fit: cover;
    border-radius: 15px;
    background-color: black;
}

video:fullscreen {
    object-fit: contain;
    border-radius: 15px;
    background-color: black;
}

/*
body, html {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: hidden;
    overflow-x: hidden;
}
*/

body {
    overscroll-behavior: contain;
}


/* Hide scrollbar for Chrome, Safari and Opera */
#root::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#root {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

.hide-scroll {
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.x-hand {
    color:#000;
    font-size:4em;
    height:100px;
    left:calc(50% - 22px);
    opacity:.8;
    position:absolute;
    top:calc(50% - 5px);
    transform-origin:bottom;
    width:100px;
}

.x-swipe-msg{
    position: absolute;
    top: calc(50% - 30px);
    left : 40%;
    font-family:'Verdana';
    font-weight : bold;
}

.x-swipe-indicator {
    position: absolute;
    top: calc(50% - 60px);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 5px dotted white;
    animation: swipe-animation 2s linear infinite;
}

y-hand {
    color:#000;
    font-size:4em;
    height:100px;
    left:calc(50% - 22px);
    opacity:.8;
    position:absolute;
    top:calc(50% - 5px);
    transform-origin:bottom;
    width:100px;
}

y-swipe-msg {
    position: absolute;
    top: calc(50% - 30px);
    left : 40%;
    font-family:'Verdana';
    font-weight : bold;
}

.y-swipe-indicator {
    position: absolute;
    top: calc(50% - 60px);
    right: calc(50% - 30px);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 5px dotted white;
    animation: swipe-up-animation 2s linear infinite;
}

@keyframes swipe-animation {
    0% {
        left: 10px;
        border-color: #bbb;
    }
    50% {
        left: 400px;
    }
    100% {
        left: 10px;
    }
}

@keyframes swipe-up-animation {
    0% {
        top: 700px;
        border-color: #bbb;
    }
    50% {
        top: 400px;
    }
    100% {
        top: 100px;
    }
}

.react-transform-wrapper {
    overflow: visible !important;
}

.comments-enter {
    background: white;
    color: black;
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    height: 10%;
    border-radius: 25px 25px 0 0;
    transition: all 0.5s;
    z-index: 3000;
}

.comments-enter-active {
    height: 60%;
    transition: all 0.5s;
}


.comments-enter-done {
    background: white;
    color: black;
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    transform: translate(0, 0);
    border-radius: 25px 25px 0 0;
    z-index: 3000;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -2px 4px 0;
}

.comments-exit {
    height: 30%;
    transition: all 0.5s;
}

.comments-exit-active {
    background: white;
    color: black;
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    height: 10%;
    opacity: 0;
    border-radius: 25px 25px 0 0;
    transition: all 0.5s;
    z-index: 3000;
}

.post-creation-done {
    background: white;
    color: black;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate(0, 0);
    border-radius: 25px 25px 0 0;
    z-index: 1050;
}

.avatar {
    width: 95%;
    height: 100%;
    border-radius: 30px 30px 30px 30px;
    margin-right: 10px;
}

canvas {
    width: 100% !important;
    height: 100% !important;
}


.bottom-navbar {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100%;
    height: 6%;
    left: 0;
    right: 0;
    background-color: #ffffff7d;
    box-shadow: 0 0 11px 0 rgb(0 0 0 / 20%);
    color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(33px);
    background-blend-mode: overlay;
    z-index: 1;
    margin: auto;
    border-radius: 30px 30px 0 0;
    text-align: center;
}

.app-bar {
    transition: 0.75s;
    height: 70px;
    background-color: #ffffff7d;
    box-shadow: 0 10px 15px rgb(0 0 0 / 20%);
    color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(33px);
    background-blend-mode: overlay;
}


button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.PinturaButtonExport {
    color: white !important;
    background-color: #3b387b !important;
}

@keyframes smooth-appear {
    to{
        bottom: 10%;
        opacity:1;
    }
}

.color-picker {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: absolute;
    bottom: 10%;
    width: 100%;
    height: 60px;
    flex-wrap: nowrap;
    overflow-y: auto;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 25px 25px 0 0;
    padding: 0px;
    background-color: #ffffffbd;
    backdrop-filter: blur(33px);
    background-blend-mode: overlay;
}

.circle-picker {
    flex-wrap: nowrap !important;
    width: auto !important;
    margin-left: 14px;
    margin-right: 14px;
}


.MuiSwipeableDrawer-paperAnchorBottom {
    height: 50%;
    max-height: 50%;
    border-radius: 25px 25px 0 0;
}

.MuiDrawer-paperAnchorBottom {
    max-height: 50%;
    border-radius: 25px 25px 0 0;
    z-index: 4000;
}

.lazy-load-image-background-weazper {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.backdrop-card {
    position: absolute;
    border-radius: 25px;
}

.profile-modification {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    border: rgba(145, 158, 171, 0.24) solid 1px;
    border-radius: 25px 25px 25px 25px;
    padding: 15px;
    height: 90%;
    width: 90%;
}

.MuiTooltip-tooltip {
    z-index: 6000;
}

.MuiTooltip-popper {
    z-index: 6000;
}

.MuiDialog-paper {
    border-radius: 25px;
}

.MuiButton-contained {
    border-radius: 25px;
}

.MuiButton-outlined {
    border-radius: 25px;
}


a {
    text-decoration: none;
    color: #3b387b;
    font-weight: bold;
    -webkit-tap-highlight-color: transparent;
}



div > a {
    text-decoration: none;
    color: black;
    -webkit-tap-highlight-color: transparent;
}